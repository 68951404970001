// Scrollbar init
function ScrollBarInit() {
	const options = {
		damping: 0.05,
		renderByPixels: true,
		syncCallbacks: true,
		alwaysShowTracks: false
	};
	const scrollbar = Scrollbar.init( document.querySelector( '.js-scroll' ), options );
	scrollbar.track.xAxis.element.remove();
	const ontop = document.querySelector( '.ontop' );
	scrollbar.addListener(({ offset }) => {
		if ( offset.y > 500 ) {
			ontop.classList.add( 'active' );
		} else {
			ontop.classList.remove( 'active' );
		}
	});
	document.querySelector( '.scroll-content' ).setAttribute( 'id', 'top' );
	document.querySelectorAll( '.scroll_link' ).forEach( item => {
		item.addEventListener('click', function(e) {
			e.preventDefault();
			const link = this.getAttribute('href');
			const target = document.getElementById(link.substring(1));
			let offsetTopValue = 0;
			if (link === '#top') {
				offsetTopValue = 0;
			}
			if (target) {
				if (window.matchMedia('(hover: hover)').matches) {
					scrollbar.scrollIntoView(target, {
						offsetTop: -scrollbar.containerEl.scrollTop + offsetTopValue,
					});
				} else {
					window.scrollTo({
						top: (target.offsetTop - document.querySelector('.header').offsetHeight),
						behavior: 'smooth'
					});
				}
				if (this.classList.contains('coubes-item')) {
					var nextElement = target.nextElementSibling;

					if (nextElement && nextElement.classList.contains('prod-accord')) {
						if (nextElement.style.display === 'none' || nextElement.style.display === '') {
							nextElement.style.display = 'block';
						} else {
							nextElement.style.display = 'none';
						}
					}

					var prodWrapper = target.closest('.prod-wrapper');
					if (prodWrapper) {
						prodWrapper.classList.add('active');

						var prodAccord = prodWrapper.querySelector('.prod-accord');

						if (prodAccord) {
							prodAccord.style.display = 'block';
						}
					}
				}
			}
		}, false);
	});
}

if (document.documentElement.clientWidth > 1023) {
	ScrollBarInit();
}

$(document).ready(function() {

    function mobileMenu(){
        const menu = $('.js-mobile-menu');
        $('.menu').clone().appendTo(menu);
        $('.lng').clone().appendTo(menu);
    }

	function inputTel(){
		$('[type="tel"]').inputmask( '+38 (099) 999 99 99',{
			"onincomplete": function(){
				$(this).addClass('not-valid');
			},
			"oncomplete": function(){
				$(this).removeClass('not-valid');
			}
		});
	}

	function select(){
		$( '.js-select' ).select2({
			language: 'en',
			width: '100%',
			minimumResultsForSearch: -1,
		});
	}

	function setInput() {
		$( 'input:not(.btn):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]), textarea')
			.each(function() {
				if ($(this).val() !== '') {
					$(this).parent().addClass('filled');
				}
			})
			.on('focus',function() {
				$(this).parent().removeClass('filled');
			})
			.on('blur',function() {
				if ($(this).val() !== '' ) {
					$(this).parent().addClass('filled');
				} else {
					$(this).parent().removeClass('filled');
				}
			});
	}

	function textareaHeight() {
		$("textarea").each(function () {
			this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
		}).on("input", function () {
			this.style.height = "auto";
			this.style.height = (this.scrollHeight) + "px";
		});
	}

	function fancy(){
		const fancyBtn = $('[data-fancybox-btn]');
		fancyBtn.click(function () {
			const popSRC = $(this).attr('href');
			setTimeout(function () {
				Fancybox.show(
					[
						{
							src: popSRC,
							preload: false,
							closeButton: false,
							dragToClose: false,
						},
					],
					{
						mainClass: 'pop-'+popSRC.substring(1),
					}
				);
			}, 100);
		});

		Fancybox.bind("[data-fancybox]", {
			placeFocusBack: false,
			dragToClose: false,
		});
	}

	function validateForm(){

		$.validator.addMethod('minNameLength', function(value, element) {
			return value.length > 2;
		});

		$.validator.addMethod('validName', function(value, element) {
			return /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ\s]+$/.test(value);
		});

		$.validator.addMethod('validTel', function(value, element) {
			if (!$(element).hasClass('not-valid')) {
				return value;
			}
		});

    	const contactForm = $("#contact-form");

		$('.js-form-submit').click(function () {
			contactForm.submit();
		});

		contactForm.validate({
			rules: {
				name: {
					required: true,
					minNameLength: true,
					validName: true
				},
				tel: {
					required: true,
					validTel: true
				}
			},
			messages: {
				name: {
					required: '',
				},
				tel: {
					required: '',
				}
			},
			errorPlacement: function(error, element) {
				element.parents('.form-item').addClass('has-error');
			},
			success: function(label, element) {
				$(element).parents('.form-item').removeClass('has-error');
			},
			submitHandler: function(form) {

				var formData = contactForm.serialize();

				$.ajax({
					type: "POST",
					url: "process_form.php",
					data: formData,
					success: function (response) {
						if (response === "success") {
							setTimeout(function () {
								Fancybox.show(
									[
										{
											src: '#popup-success',
											preload: false,
											closeButton: false
										},
									],
								);
								contactForm.find('input, textarea').val('');
							}, 100);
						} else {
							alert("Під час відправки повідомлення сталася помилка.");
						}
					}
				});
			}
		});
	}

    function menu(){
        const btn = $('.js-ico-menu');
        const menu = $('.mobile-menu');
        const menuItem = $('.js-mobile-menu .menu a');
        btn.click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            menu.slideToggle();
        });
		menuItem.click(function (e) {
			e.preventDefault();
			menu.slideUp();
			btn.removeClass('active');
			var elementClick = $(this).attr("href").replace('/', '');
			var destination = $(elementClick).offset().top - 30;
			jQuery("html:not(:animated),body:not(:animated)").animate({scrollTop: destination}, 800);
			return false;
		});
    }

    function footerMenu(){
        const btn = $('.footer-title');
        btn.click(function (e) {
            e.preventDefault();
            if ($(window).outerWidth() < 768) {
				$(this).parents('.footer-box-item').toggleClass('active').find('.footer-content').slideToggle();
			}
        });
    }

    function ontop(){
        var btn = $('.js-ontop');
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll >= 500) {
                btn.addClass('active');
            } else {
                btn.removeClass('active');
            }
        });
        btn.on('click',function() {
            $('html, body').animate({scrollTop: 0},500);
            return false;
        });
    }

	function tabs() {
		const tabs = $('.js-tabs');
		const tabBtn = tabs.find('.tab-item');
		const tabContent = tabs.find('.tab-content-item');
		tabBtn.click(function () {
			tabBtn.removeClass('active');
			$(this).addClass('active');
			tabContent.removeClass('active');
			tabContent.eq($(this).index()).addClass('active');
		});
	}

	function products() {
		const btn = $('.js-prod .prod-wrapper h2');
		btn.click(function () {
			$(this).next('.prod-accord').slideToggle();
			$(this).closest('.prod-wrapper').toggleClass('active');
		});
	}

	function servicesMob() {
		const btn = $('.services-item i');
		btn.click(function () {
			$(this).closest('.services-item').toggleClass('active').find('.services-item-text').slideToggle();
		});
	}

	var cookie = getCookie('set_cookie');
	if(cookie === undefined) {
		setTimeout(function () {
			Fancybox.show(
				[
					{
						src: '#popup-cookie',
						preload: false,
						closeButton: false
					},
				],
			);
		}, 100);
	}

	function scrollOpenMob(){
		let btn = $('.coubes-item.scroll_link');
		btn.click(function () {
			if ($(window).width() < 768) {
				var elementClick = $(this).attr("href").replace('/', '');
				var destination = $(elementClick).offset().top - $('header').outerHeight() - 7;
				jQuery("html:not(:animated),body:not(:animated)").animate({scrollTop: destination}, 800);
				$(elementClick).addClass('active');
				setTimeout(function () {
					$(elementClick).find('.prod-accord').slideDown(1000);
				},800);
				return false;
			}
		});
	}

	function altIMG() {
		var productIMG = $('.prod-box-item-img img');
		productIMG.each(function () {
			var productNAME = $(this).parents('.prod-box-item').find('.prod-box-item-name').text();
			$(this).attr('alt',productNAME);
		});
	}

    mobileMenu();
	inputTel();
	select();
	setInput();
	textareaHeight();
	scrollOpenMob();
	fancy();
    menu();
	validateForm();
    footerMenu();
    ontop();
	tabs();
	products();
	servicesMob();
	altIMG();
});

function setCookie(name, value, options = {}) {
	let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
	for (let optionKey in options) {
		updatedCookie += "; " + optionKey;
		let optionValue = options[optionKey];
		if (optionValue !== true) {
			updatedCookie += "=" + optionValue;
		}
	}
	document.cookie = updatedCookie;
}

function getCookie(name) {
	let matches = document.cookie.match(new RegExp(
		"(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
}


$(document).on("click", ".popup-cookie .btn", function (e) {
	let date = new Date();
	date.setDate(date.getDate() + 1);
	setCookie('set_cookie', '1', {path:'/', 'expires': date.toGMTString() });
	Fancybox.close();
});
